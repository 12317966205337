import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Card, CardContent, Hidden } from '@material-ui/core';
import VillageWelcomeImage from 'images/village_welcome.png';
import { ContentComponent } from 'containers/ContentComponents';

const styles = {
    base: {
        marginBottom: 15,
        position: 'relative',
    },
    image: {
        position: 'absolute',
        left: 15,
        bottom: 0,
    },
};

class WelcomeVillage extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    /**
     * Render component
     */
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.base}>
                <Card>
                    <CardContent>
                        <Hidden xsDown>
                            <div>
                                <img className={classes.image} src={VillageWelcomeImage} alt="" />
                                <div style={{ paddingLeft: 150, textAlign: 'left' }}>
                                    <ContentComponent componentName="Auth.VillageWelcome" />
                                </div>
                            </div>
                        </Hidden>
                        <Hidden smUp>
                            <ContentComponent componentName="Auth.VillageWelcome" />
                        </Hidden>
                    </CardContent>
                </Card>
            </div>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(WelcomeVillage);
