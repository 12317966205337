import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Col, Row } from 'react-flexbox-grid';
import { Button, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { withRouter } from 'react-router';
import Link from 'ui/base/Link';
import { resendConfirmation } from 'reducers/users';
import { connect } from 'react-redux';

const styles = {
    row: {
        height: '100%',
        textAlign: 'left',
    },
};

class JoinSuccessPage extends React.Component {
    /**
     * Component state
     */
    state = {
        emailAlreadySend: false,
    };

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    handleResendClick = (event) => {
        const email = this.props.location.state && this.props.location.state.email;
        email && this.props.reconfirm(email);
        this.setState({ emailAlreadySend: true });
    };

    /**
     * Render component
     */
    render() {
        const { classes, error } = this.props;
        const email = this.props.location.state && this.props.location.state.email;

        return (
            <Row className={classes.row} center="xs" middle="xs">
                <Col xs={12} sm={6} lg={4}>
                    <div className={classes.cardContainer}>
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardContent>
                                <div style={{ textAlign: 'center' }}>
                                    <Typography style={{ margin: '20px 0' }} color="primary">
                                        <CheckCircle style={{ verticalAlign: 'middle' }} />
                                    </Typography>
                                    <Typography>
                                        {` An email with a link to confirm your registration has been sent to `}
                                        <b>{email}</b>
                                    </Typography>
                                </div>
                            </CardContent>
                            <CardActions>
                                <Link to={`/login`} tabIndex={-1}>
                                    <Button tabIndex={1}>Go back</Button>
                                </Link>
                                <div style={{ flex: 1 }} />
                                {!this.state.emailAlreadySend && (
                                    <Button color="secondary" onClick={this.handleResendClick}>
                                        Resend email
                                    </Button>
                                )}
                            </CardActions>
                        </Card>
                    </div>
                </Col>
            </Row>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapDispatchToProps = (dispatch) => {
    return {
        reconfirm: (email) => dispatch(resendConfirmation(email)),
    };
};

export default withStyles(styles)(withRouter(connect(null, mapDispatchToProps)(JoinSuccessPage)));
