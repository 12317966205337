import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Col, Row } from 'react-flexbox-grid';
import { Button, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import ValidatedTextField from 'ui/base/ValidatedTextField';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import Link from 'ui/base/Link';
import { askResetPassword } from 'reducers/users';
import { push } from 'connected-react-router';
import DefaultError from 'ui/base/DefaultError';
import { LocaleConsumer } from 'containers/locale/LocaleProvider'

const styles = {
    row: {
        height: '100%',
        textAlign: 'left',
    },
};

class AskPasswordPage extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    renderForm() {
        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <Field
                            type="text"
                            name="email"
                            label={getTranslation("email")}
                            component={ValidatedTextField}
                            autoFocus
                            required
                            fullWidth
                        />
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    renderActions() {
        const { handleSubmit, submitting } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <CardActions>
                        <Link to={`/login`} tabIndex={-1}>
                            <Button tabIndex={1}>{getTranslation("back")}</Button>
                        </Link>
                        <div style={{ flex: 1 }} />
                        <Button color="secondary" onClick={handleSubmit} disabled={submitting}>
                            {getTranslation("send")}
                        </Button>
                    </CardActions>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Render component
     */
    render() {
        const { classes, error } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <Row className={classes.row} center="xs" middle="xs">
                        <Col xs={12} sm={6} lg={4}>
                            <div className={classes.cardContainer}>
                                <Card classes={{ root: classes.cardRoot }}>
                                    <CardContent>
                                        <Typography variant="h6" gutterBottom>
                                            {getTranslation("lost_password_title")}
                                        </Typography>
                                        <br />
                                        <Typography color="textSecondary">
                                            {getTranslation("lost_password_enter_email")}
                                        </Typography>
                                        <DefaultError gutterTop>{error}</DefaultError>
                                        <br />
                                        {this.renderForm()}
                                    </CardContent>
                                    {this.renderActions()}
                                </Card>
                            </div>
                        </Col>
                    </Row>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(
    reduxForm({
        form: 'lostPassword',
        onSubmit: (values, dispatch) => {
            return dispatch(askResetPassword(values))
                .then(() => {
                    dispatch(
                        push('/login', {
                            email: values.email,
                            isAfterAskResetPassword: true,
                        })
                    );
                })
                .catch((error) => {
                    throw new SubmissionError({ _error: error.getMessage() });
                });
        },
    })(AskPasswordPage)
);
