import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { Card, CardActions, CardContent } from '@material-ui/core';
import Logo from 'images/logo_deva_world.png';
import withStyles from '@material-ui/core/styles/withStyles';
import { Col, Row } from 'react-flexbox-grid';
import { login } from 'reducers/users';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import { withRouter } from 'react-router-dom';
import Link from 'ui/base/Link';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { connect } from 'react-redux';
import CheckCircle from '@material-ui/icons/CheckCircle';
import ValidatedTextField from 'ui/base/ValidatedTextField';
import DefaultError from 'ui/base/DefaultError';
import { ContentComponent } from 'containers/ContentComponents';
import {LocaleConsumer} from 'containers/locale/LocaleProvider'
import PasswordField from "ui/base/PasswordField";

const styles = () => ({
    row: {
        height: '100%',
    },
    cardContainer: {
        padding: 15,
    },
    cardRoot: {
        padding: 20,
        '&:last-child': {
            paddingBottom: 15,
        },
    },
    snackbar: {
        marginBottom: 25,
    },
});

class LoginPage extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    renderRegistrationConfirmation() {
        const {
            classes,
            location: { state },
        } = this.props;

        if (!state) return;
        const { isAfterRegistration, isAlreadyActivated } = state;

        const confirmMessage = (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <span>
                        <Typography style={{ display: 'inline' }} color="primary">
                            <CheckCircle style={{ verticalAlign: 'middle' }} />
                        </Typography>
                        {getTranslation("login_you_can_now")}
                    </span>
                )}
            </LocaleConsumer>
        );

        return (
            isAfterRegistration &&
            isAlreadyActivated && <SnackbarContent className={classes.snackbar} message={confirmMessage} />
        );
    }

    renderAccountConfirmationMessage() {
        const { confirmationKey, displayName, email, error } = this.props;

        if (confirmationKey) {
            return (
                <LocaleConsumer>
                    {({ getTranslation }) => (
                        <div>
                            <br />
                            {displayName && <Typography>{getTranslation("hello")} {displayName}</Typography>}
                            {email && (
                                <Typography>
                                    {getTranslation("login_please_enter_password")}
                                </Typography>
                            )}
                            {error && <DefaultError>{error}</DefaultError>}
                            <br />
                        </div>
                    )}
                </LocaleConsumer>
            );
        }
    }

    renderForm() {
        const { error, classes, confirmationKey } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div className={classes.form}>
                        {!confirmationKey && (
                            <div>
                                <Field
                                    type="email"
                                    name="email"
                                    label={getTranslation("email")}
                                    error={!!error}
                                    helperText={error}
                                    component={ValidatedTextField}
                                    required
                                    fullWidth
                                />
                                <br />
                                <br />
                            </div>
                        )}
                        <Field
                            name="password"
                            label={getTranslation("password")}
                            component={PasswordField}
                            required
                            fullWidth
                        />
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    renderActions() {
        const { submitting, handleSubmit, confirmationKey } = this.props;

        return (
            <LocaleConsumer>
                {({ getTranslation }) => (
                    <div>
                        <CardContent>
                            <Button
                                color="secondary"
                                variant="contained"
                                style={{ width: '100%' }}
                                onClick={handleSubmit}
                                disabled={submitting}>
                                {getTranslation("login")}
                            </Button>
                        </CardContent>
                        {!confirmationKey && (
                            <CardActions>
                                {/*<Link to={`/join`} tabIndex={-1}>*/}
                                {/*    <Button tabIndex={1} disabled={submitting}>*/}
                                {/*        Sign up*/}
                                {/*    </Button>*/}
                                {/*</Link>*/}
                                <div style={{ flex: 1 }} />
                                <Link to={`/lost_password`} tabIndex={-1}>
                                    <Button tabIndex={1} disabled={submitting}>
                                        {getTranslation("login_lost_password")}
                                    </Button>
                                </Link>
                            </CardActions>
                        )}
                    </div>
                )}
            </LocaleConsumer>
        );
    }

    /**
     * Render component
     */
    render() {
        const { classes } = this.props;

        return (
            <Row className={classes.row} center="xs" middle="xs">
                <Col xs={12} sm={6} lg={4}>
                    <div className={classes.cardContainer}>
                        {this.renderRegistrationConfirmation()}
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardContent>
                                <Typography align="center">
                                    <img src={Logo} height={40} alt="" style={{ marginBottom: 20 }} />
                                </Typography>
                                <ContentComponent componentName="Auth.Welcome" />
                                <br />
                                <br />
                                {/*Quick fix*/}
                                {window.location.hostname === 'betadeva.mentia.me' && (
                                    <Typography variant="h6" color="primary">
                                        BETA
                                    </Typography>
                                )}
                                <br />
                                {this.renderAccountConfirmationMessage()}
                                {this.renderForm()}
                            </CardContent>
                            {this.renderActions()}
                            <br />
                            <CardContent>
                                <ContentComponent componentName="Auth.LoginMessage" />
                            </CardContent>
                        </Card>
                    </div>
                </Col>
            </Row>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

const mapStateToProps = (state, ownProps) => {
    const query = new URLSearchParams(ownProps.location.search);
    const confirmationKey = query.get('key');
    const displayName = query.get('displayName');
    const email = query.get('email');

    return {
        confirmationKey,
        displayName,
        email,
        initialValues: {
            email: ownProps.location.state && ownProps.location.state.email,
        },
    };
};

export default withStyles(styles)(
    withRouter(
        connect(mapStateToProps)(
            reduxForm({
                form: 'login',
                onSubmit: (values, dispatch, ownProps) => {
                    const _key = ownProps.confirmationKey;
                    return dispatch(login({ _key, ...values })).catch((error) => {
                        throw new SubmissionError({
                            _error: error.getMessage(),
                        });
                    });
                },
            })(LoginPage)
        )
    )
);
