import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import { Col, Row } from 'react-flexbox-grid';
import { Button, Card, CardActions, CardContent, Typography } from '@material-ui/core';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import validator from 'validator';
import { resetPassword } from 'reducers/users';
import { push } from 'connected-react-router';
import { withRouter } from 'react-router-dom';
import DefaultError from 'ui/base/DefaultError';
import PasswordField from "ui/base/PasswordField";
import {LocaleConsumer} from 'containers/locale/LocaleProvider'

const styles = {
    row: {
        height: '100%',
        textAlign: 'left',
    },
};

class ResetPasswordPage extends React.Component {
    /**
     * Component state
     */
    state = {};

    /**
     * Constructor
     * @param props
     */
    constructor(props) {
        super(props);
    }

    renderForm() {
        return (
            <LocaleConsumer>
            {({ getTranslation }) => (
                <div>
                    <Field
                        name="password"
                        label={getTranslation("password")}
                        component={PasswordField}
                        required
                        fullWidth
                    />
                    <br />
                    <br />
                    <Field
                        name="password_confirm"
                        label={getTranslation("user_confirm_password")}
                        component={PasswordField}
                        required
                        fullWidth
                    />
                </div>
            )}
            </LocaleConsumer>
        );
    }

    renderActions() {
        const { handleSubmit, submitting } = this.props;

        return (
            <CardActions>
                <div style={{ flex: 1 }} />
                <Button color="secondary" onClick={handleSubmit} disabled={submitting}>
                    Send
                </Button>
            </CardActions>
        );
    }

    /**
     * Render component
     */
    render() {
        const { classes, error } = this.props;

        return (
            <Row className={classes.row} center="xs" middle="xs">
                <Col xs={12} sm={6} lg={4}>
                    <div className={classes.cardContainer}>
                        <Card classes={{ root: classes.cardRoot }}>
                            <CardContent>
                                <Typography variant="h6" gutterBottom>
                                    Lost password
                                </Typography>
                                <Typography color="textSecondary">Enter your new password</Typography>
                                <DefaultError gutterTop>{error}</DefaultError>
                                <br />
                                {this.renderForm()}
                            </CardContent>
                            {this.renderActions()}
                        </Card>
                    </div>
                </Col>
            </Row>
        );
    }

    /**
     * Properties initilization
     */
    static propTypes = {};
    static defaultProps = {};
}

export default withStyles(styles)(
    withRouter(
        reduxForm({
            form: 'lostPassword',
            validate: (values) => {
                const errors = {};

                if (!values.password) {
                    errors.password = 'Required';
                } else if (!validator.isLength(values.password, { min: 6 })) {
                    errors.password = 'Password must be at least 6 characters long';
                }

                if (!values.password_confirm) {
                    errors.password_confirm = 'Required';
                } else if (values.password !== values.password_confirm) {
                    errors.password_confirm = 'Your password and confirmation password do not match';
                }

                return errors;
            },
            onSubmit: (values, dispatch, props) => {
                const { id, key } = props.match.params;
                return dispatch(resetPassword(id, key, values))
                    .then(() => {
                        dispatch(
                            push('/login', {
                                isAfterResetPassword: true,
                            })
                        );
                    })
                    .catch((error) => {
                        throw new SubmissionError({ _error: error.getMessage() });
                    });
            },
        })(ResetPasswordPage)
    )
);
